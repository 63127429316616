html,
.mainbody,
#root {
  display: block;
  background-color: #151515;
  /* height: auto;
  width: 100vw; */
  position: absolute;
  flex-direction: column;
  /* scroll-behavior: smooth; */

  width: 100%;
  /* height: 100%; */
  margin: 0;
  overflow-x: hidden;
  padding: 0;
}

main {
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
}

canvas:not(.v360-image-container) {
  min-width: 100vw;
  min-height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 100%;
  position: absolute;

  z-index: 2;
}

.wrapone {
  z-index: 2;
}

@font-face {
  font-family: "Px-Grotesk-Regular";
  src: local("Px-Grotesk-Regular"),
    url("/src/fonts/Px-Grotesk-Regular.ttf") format("truetype");
  font-weight: bold;
}

.mainbody {
  display: block;
  background-color: #151515;
  height: 200vh;
  width: 100vw;
  position: absolute;
  flex-direction: column;
  overflow: visible;
}

.ButtonMenu {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
  margin: 8px;
  color: #eef7ed;
  font-size: 15px;
  font-family: "Fira Code", monospace;
  font-weight: 100;
  z-index: 9;
}

button {
  color: #eef7ed;
  border-style: none;
  background-color: transparent;
  font-size: 15px;
  font-family: "Fira Code", monospace;
  font-weight: 100;
  margin-top: 0;
  transition: color 1.5ms ease-in;
  text-decoration: none;
}

button:hover {
  color: #37ff00;
  transition: color 1.5ms ease-in;
}

/* * {
  box-sizing: inherit;
} */

.LeftLogo {
  /*background-color: black;*/
  height: 100vh;
  width: 100%;
  display: flex;
  margin: 8px;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  z-index: 9;
  position: relative;
  left: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
}

h1 {
  color: #eef7ed;
  font-size: 60px;
  font-family: "Fira Code", monospace;
  /* font-family: 'Px-Grotesk-Regular'; */
  font-weight: 400;
  margin-bottom: 0;
}

.headertext {
  color: #eef7ed;
  flex-wrap: wrap;
  width: 10%;
  z-index: 9;
}

.text {
  display: inline-block;
  width: 20px;

  z-index: 9;
}

/* h2 {
  color: #eef7ed;
  font-size: 20px;
  font-family: "Fira Code", monospace;
  margin-bottom: 0;
} */

p {
  color: #eef7ed;
  font-size: 15px;
  font-family: "Fira Code", monospace;
  font-weight: 100;
  margin-top: 0;
}

li {
  color: #eef7ed;
  font-size: 15px;
  font-family: "Fira Code", monospace;
  margin-top: 0;
}

details {
  color: #eef7ed;
  font-size: 15px;
  font-family: "Fira Code", monospace;
  margin-top: 0;
}

a {
  margin: 0px;
  color: #eef7ed;
  text-decoration: none;
  text-decoration-thickness: 0px;
}

#about {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  left: 0;
  right: 0;

  margin-top: 10%;
  padding-top: 20%;

  margin-bottom: 26%;
}

.about {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;

  /* background-color: rgba(255, 255, 255, 0.15); */
  /* background-color: #1a1a1a; */
  backdrop-filter: blur(5px);

  border-bottom: white 0.5px;
  border-top: white 0.5px;
  border-style: solid;
  border-right: none;
  border-left: none;

  height: 250 px;
  width: 100vw;

  left: 0;
  right: 0;

  padding: 60px;

  z-index: 8;
  pointer-events: none;
}

footer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  object-fit: contain;

  /* background-color: rgba(255, 255, 255, 0.15); */
  /* background-color: #1a1a1a; */
  backdrop-filter: blur(7px);
  border: #eef7ed 0.1px;
  border-style: solid;

  width: 100vw;

  padding: 60px;

  left: 0;
  right: 0;

  z-index: 8;
  pointer-events: none;

  margin-bottom: 15%;
  margin-top: 5%;
}

/* .connect {
  display: inline-block;
  position: relative;
  font-family: "Px-Grotesk-Regular";
  justify-content: space-between;
} */

h2 {
  font-size: 30px;
  color: #eef7ed;
  margin-bottom: 5%;
  margin-right: 5%;
  display: inline-block;
  font-family: "Px-Grotesk-Regular";
}

h4 {
  font-size: 30px;
  color: #eef7ed;
  margin-bottom: 5%;

  display: inline-block;
  font-family: "Px-Grotesk-Regular";
}

#connect {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  font-family: "Px-Grotesk-Regular";
}

.Links {
  font-size: 13.7px;

  font-family: "Fira Code", monospace;
  display: flex;

  pointer-events: auto;
  margin-top: 3%;

  color: #eef7ed;
}

.socials {
  transition: color 1.5ms ease-in;
  font-size: 14px;

  color: #eef7ed;
}

.socials:hover {
  color: #37ff00;
  transition: color 1.5ms ease-in;
}

.dot-flashing {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;

  margin-left: 0px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: transparent;
  color: #eef7ed;
  animation: dotFlashing 2.5s infinite alternate;
  animation-delay: 3.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;

  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: 15px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: transparent;
  color: #eef7ed;
  animation: dotFlashing 2.5s infinite alternate;
  animation-delay: 3s;
}

.dot-flashing::after {
  left: 30px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: transparent;
  color: #eef7ed;
  animation: dotFlashing 2.5s infinite alternate;
  animation-delay: 4s;
}

@keyframes dotFlashing {
  0% {
    background-color: transparent;
  }
  50%,
  50% {
    background-color: #eef7ed;
  }
}

/* WORK */

/* .workmenu {
  display: grid;
  align-items: ;
  justify-content: space-between;
  margin: 8px;
  color: #eef7ed;
  font-size: 15px;
  font-family: "Fira Code", monospace;
  font-weight: 100;
} */

.box {
  --b: 10px; /* thickness of the border */
  --c: red; /* color of the border */
  --w: 40px; /* width of border */

  border: var(--b) solid transparent; /* space for the border */
  --g: #0000 90deg, var(--c) 0;
  background: conic-gradient(from 90deg at top var(--b) left var(--b), var(--g))
      0 0,
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--g)) 100% 0,
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--g)) 0 100%,
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--g)) 100%
      100%;
  background-size: var(--w) var(--w);
  background-origin: border-box;
  background-repeat: no-repeat;

  /*Irrelevant code*/
  width: 500px;
  height: 300px;
  box-sizing: border-box;
  margin: 5px;
  display: inline-flex;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  line-height: 90px;
  z-index: 10;
}

#work {
  padding-top: 2%;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20%;

  position: relative;
  z-index: 3;
  pointer-events: none;
  left: 0;
  right: 0;
}

.workborder {
  display: flex;
  justify-content: center;
  width: 100vw;
}

.cards1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card1:hover {
  filter: brightness(40%);
}

.card3:hover {
  filter: brightness(40%);
}

.card5:hover {
  filter: brightness(40%);
}

.card6:hover {
  filter: brightness(40%);
}

.card7:hover {
  filter: brightness(40%);
}

.card1 {
  display: flex;
  margin: 3px;
  width: 150px;
  height: 250px;
  background-image: url("/public/object1.png");
  background-position: center;
  filter: none;
  transition: filter 0.2s;
  background-size: 300px;
  border-style: solid;
  border-color: #eef7ed;
  border-width: 0.5px;
  padding: 32px;
  border-radius: 10px;
  text-align: center;
  align-items: flex-end;
  position: relative;
  pointer-events: auto;
}

.card3 {
  display: flex;
  margin: 3px;
  width: 150px;
  height: 250px;
  background-image: url("/public/object2.png");
  filter: none;
  transition: filter 0.2s;
  background-position: center;
  background-size: 300px;
  background-color: #1a1a1a;
  border-style: solid;
  border-color: #eef7ed;
  border-width: 0.5px;
  padding: 32px;
  border-radius: 10px;
  text-align: center;
  align-items: flex-end;
  pointer-events: auto;
}

.card5 {
  display: flex;
  margin: 3px;
  width: 150px;
  height: 250px;
  background-image: url("/public/object3.png");
  filter: none;
  transition: filter 0.2s;
  background-position: center;
  background-size: 250px;
  background-color: #1a1a1a;
  border-style: solid;
  border-color: #eef7ed;
  border-width: 0.5px;
  padding: 32px;
  border-radius: 10px;
  text-align: center;
  align-items: flex-end;
  pointer-events: auto;
}

.card6 {
  display: flex;
  margin: 3px;
  width: 150px;
  height: 250px;
  background-image: url("/public/bottle.png");
  filter: none;
  transition: filter 0.2s;
  background-position: center;
  background-size: 300px;
  background-color: #1a1a1a;
  border-style: solid;
  border-color: #eef7ed;
  border-width: 0.5px;
  padding: 32px;
  border-radius: 10px;
  text-align: center;
  align-items: flex-end;
  pointer-events: auto;
}

.card7 {
  display: flex;
  margin: 3px;
  width: 373px;
  height: 100px;
  background-image: url("/public/misc.png");
  filter: none;
  transition: filter 0.2s;
  background-position: center;
  background-size: 500px;
  background-position: bottom;
  background-color: #1a1a1a;
  border-style: solid;
  border-color: #eef7ed;
  border-width: 0.5px;
  padding: 32px;
  border-radius: 10px;
  text-align: center;
  align-items: flex-end;
  pointer-events: auto;
}

li {
  list-style: none;
}

.list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.leftside1 {
  display: flex;
  position: relative;
  padding-top: 60px;
  width: 100%;
  justify-content: flex-end;
}

.rightside1 {
  display: flex;
  position: relative;
  padding-top: 60px;
  width: 100%;
  justify-content: flex-start;
}

#workdescription {
  font-family: Px-Grotesk-Regular;
  font-weight: 100;
  font-size: 30px;

  display: flex;
  flex-direction: column;
  padding-left: 20%;

  top: 60%;
  width: 600px;

  z-index: 7;
  justify-content: flex-start;
  align-items: flex-start;
}

#workdescription1 {
  font-family: Px-Grotesk-Regular;
  font-weight: 100;
  font-size: 30px;

  display: flex;

  justify-content: right;

  z-index: 7;
}

.projecttext {
  /* font-family: Px-Grotesk-Regular;
  font-weight: 100; */
  font-size: 30px;
  font-family: "Fira Mono", monospace;
  display: flex;

  padding-top: 15%;

  z-index: 7;
  justify-content: right;
  align-items: flex-start;
}

.projecttext2 {
  /* font-family: Px-Grotesk-Regular;
  font-weight: 100; */
  font-size: 30px;
  font-family: "Fira Mono", monospace;
  display: flex;
}

.projecttext3 {
  /* font-family: Px-Grotesk-Regular;
  font-weight: 100; */
  font-size: 30px;
  font-family: "Fira Mono", monospace;
  display: flex;

  padding-top: 15%;

  z-index: 7;
  justify-content: right;
  align-items: flex-start;
}

#projecttext2 {
  /* font-family: Px-Grotesk-Regular;
  font-weight: 100; */
  font-size: 30px;
  font-family: "Fira Mono", monospace;
  display: flex;

  position: absolute;
  top: 60%;
  left: 36.5vw;

  padding: 10%;
  z-index: 7;
  justify-content: right;
  align-items: flex-start;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

.workbody {
  height: auto;

  display: flex;
  position: relative;
  justify-content: space-between;

  align-items: flex-start;
  padding-top: 10%;
  overflow: visible;
}

.gridexp {
  /*width: 100vw;
  height: 100vh;
  padding: 1rem; */

  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(10, 10vw);
  grid-gap: 3px;
  pointer-events: all;
  height: auto;

  overflow: hidden;

  /* row-gap: 1rem;
  column-gap: 1rem;
  width: 100%;
  height: 100%;
  object-fit: cover; */
}

.gridexpmisc {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(10, 10vw);
  grid-gap: 3px;
  pointer-events: all;
  height: 900px;

  overflow: hidden;
}

.packaginglayout {
  padding-top: 15%;
}

/* .backinvert {
  background-color: transparent;

  filter: invert(1);
} */

.buttonmenutext {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  /* backdrop-filter: blur(5px);
  border-bottom: white 0.5px;
  border-top: white 0.5px;
  border-style: solid;
  border-right: white 0.5px;
  border-left: white 0.5px;
  border-radius: 10px;
  width: 100vw; */
}

.image-text-home1 {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #eef7ed;

  color: #eef7ed;

  background-image: none;
  transition: background-image 0.3s ease;
  pointer-events: all;
}

.image-text__title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.image-text-home2 {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #eef7ed;

  color: #eef7ed;

  background-image: none;
  transition: background-image 0.3s ease;
  pointer-events: all;
}

.image-text-home3 {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #eef7ed;

  color: #eef7ed;

  background-image: none;
  transition: background-image 0.3s ease;
  pointer-events: all;
}

.image-text-home4 {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #eef7ed;

  color: #eef7ed;

  background-image: none;
  transition: background-image 0.3s ease;
  pointer-events: all;
}

.image-text-home5 {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #eef7ed;

  color: #eef7ed;

  background-image: none;
  transition: background-image 0.3s ease;
  pointer-events: all;
}

.image-text-home6 {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #eef7ed;

  color: #eef7ed;

  background-image: none;
  transition: background-image 0.3s ease;
  pointer-events: all;
}

.image-text-home1:hover {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/public/img/barnacles.jpg");
}

.image-text-home2:hover {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/public/img/plantcell.png");
}

.image-text-home3:hover {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/public/img/scouttextimg.png");
}

.image-text-home4:hover {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/public/img/chrome.jpg");
}

.image-text-home5:hover {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/public/img/cad.png");
}

.image-text-home6:hover {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;

  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/public/img/misc1.jpg");
}

h3:not(.smallnumber) {
  font-family: "Fira Code", monospace;

  font-size: 120px;
  filter: invert(0);
}

/* h3:hover {
  filter: invert(1);

  transition: filter 2.5ms ease-in;
} */

.smallnumber {
  font-family: Px-Grotesk-Regular;
  font-style: italic;

  -webkit-background-clip: text;
  background-clip: text;
  color: #eef7ed;
  background-color: #eef7ed;
  padding: 10px; /* Adjust padding as needed */
  box-sizing: border-box;

  font-size: 35px;
}

.img_gallery {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
  overflow: auto;
  position: relative;
}

.img_gallery2 {
  display: flex;
  position: absolute;
  align-items: flex-start;

  width: 750px;
  height: 925px;
  object-fit: contain;
  padding-top: 3%;
  border-radius: 2px;
  overflow: auto;

  left: 0;
}

.img_gallery3 {
  display: flex;

  justify-content: space-between;
  align-items: center;
  right: 0;
  width: 600px;

  object-fit: contain;
}

.img_gallery4 {
  display: flex;

  padding-bottom: 0%;
  justify-content: flex-start;
  align-items: center;

  height: auto;
  width: 400px;
}

.img_gallery5 {
  display: flex;

  padding-bottom: 0%;
  justify-content: flex-start;
  align-items: center;

  height: auto;
  width: 400px;
}

.img_gallery6 {
  display: flex;

  padding-bottom: 0%;
  justify-content: flex-start;
  align-items: center;

  height: auto;
  width: 400px;
}

.image1 {
  display: flex;
  position: absolute;
  align-items: flex-start;

  width: 750px;
  height: 950px;
  object-fit: contain;
  padding-top: 5%;
  border-radius: 2px;
  overflow: hidden;

  left: 0;
}

.image2,
.image3 {
  display: inline-block;
  width: auto;
  height: 275px;
  object-fit: contain;
}

.galleryplacement {
  display: flex;
  align-items: center;
  padding-top: 15%;
  /* padding-right: 20%;
  padding-left: 20%; */
  padding-bottom: 5%;
  justify-content: center;
  object-fit: fill;
}

.galleryplacement1 {
  display: flex;
  align-items: center;
  padding-top: 60%;
  /* padding-right: 20%;
  padding-left: 20%; */
  padding-bottom: 5%;
  justify-content: center;
  object-fit: fill;
}

.glassmagnifier {
  border: solid;
  border-radius: 50%;
  border-style: hidden;

  height: 40%;
  width: 40%;

  overflow: hidden;

  z-index: 9998;
}

.bigimage {
  display: none;
}

.tsbox {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: auto;
  margin: 30px;
  padding: 40px;
  pointer-events: all;
  cursor: pointer;
}

.v360-viewport {
  display: inline-block;

  background: none;
  width: 20%;
  height: auto;
  object-fit: contain;

  background-position: center;
  pointer-events: all;
  cursor: pointer;
}

.v360-image-container {
  width: 25vw;
  display: inline-block;
  pointer-events: all;
  cursor: pointer;
}
.v360-viewer-container {
  width: 25vw;
  display: inline-block;
  pointer-events: all;
  cursor: pointer;
}

.centered {
  display: flex;
  align-items: center;
  padding-top: 10%;

  justify-content: center;
  overflow: visible;
}

#centered {
  display: flex;
  align-items: center;
  padding-top: 10%;

  justify-content: center;
  overflow: visible;
}

.Project1,
.Project2,
.Project3,
.Project4,
.CAD,
.Misc {
  margin-left: 15%;
  margin-right: 15%;

  object-fit: contain;
  overflow: visible;
}

.carousel carousel-slider {
  height: 100px;
}

.h2description {
  font-family: "Fira Code";
  font-size: 15px;
  font-weight: 100;
}
/* 
.circlebox {
  display: flex;
  position: relative;
  width: 500px;
  height: 500px;
  border: solid;
  border-radius: 50%;
  overflow: hidden;
} */

.video_gallery {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
}

.gridexp img:hover,
video:hover {
  filter: brightness(50%);
}

.gridexp img,
video {
  filter: none;
  transition: filter 0.5s;
}

.gridexpmisc img:hover,
video:hover {
  filter: brightness(50%);
}

.gridexpmisc img,
video {
  filter: none;
  transition: filter 0.5s;
}

.img_gallery:hover + .appear {
  display: block;
  pointer-events: none;
}

.video_gallery:hover + .appear {
  display: block;
  pointer-events: none;
}

.cell {
  position: relative;
}

.appear {
  display: none;
  position: absolute;
  filter: none;
  transition: 2ms;

  top: 0;
  left: 0;

  padding: 2%;
  z-index: 1;
  color: #eef7ed;
}

.titles {
  color: #eef7ed;
  font-size: 18px;
  font-weight: 400;
}

/* BRANDING */

.cell--video {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 4;
}

.cell--1 {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 5;
}

.cell--2 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 8;
  grid-row-end: 11;
}

.cell--3 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 5;
  grid-row-end: 11;
}

.cell--4 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 4;
  grid-row-end: 5;
}

/* JEWELRY */

.cell--video2 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 5;
}

.cell--5 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 4;
}

.cell--7 {
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 5;
}

.cell--8 {
  grid-column-start: 6;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 5;
}

/* GARMENTS */

.cell--video4 {
  grid-column-start: 6;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 9;
}

.cell--video3 {
  grid-column-start: 6;
  grid-column-end: 9;
  grid-row-start: 6;
  grid-row-end: 12;
}

.cell--12 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 5;
}

.cell--13 {
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 4;
}

.cell--14 {
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 4;
  grid-row-end: 7;
}

.cell--15 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 5;
  grid-row-end: 7;
}

.cell--16 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 5;
  grid-row-end: 7;
}

.cell--17 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 5;
  grid-row-end: 7;
}

.cell--18 {
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 7;
  grid-row-end: 9;
}

/* PRODUCT DESIGN */

.cell--19 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 4;
}

.cell--20 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 4;
  grid-row-end: 7;
}

.cell--21 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 7;
  grid-row-end: 9;
}

.cell--22 {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 4;
}

.cell--23 {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 4;
}

.cell--24 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 9;
  grid-row-end: 11;
}

.cell--25 {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 7;
  grid-row-end: 9;
}

.cell--26 {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 7;
  grid-row-end: 9;
}

.cell--27 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 9;
  grid-row-end: 11;
}

.cell--28 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 4;
  grid-row-end: 7;
}

.cell--29 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 9;
  grid-row-end: 11;
}

.cell--30 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 11;
  grid-row-end: 13;
}

.cell--video5 {
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 7;
  grid-row-end: 8;
}

.cell--video6 {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 4;
}

.cell--31 {
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 8;
  grid-row-end: 11;
}

.cell--32 {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 7;
  grid-row-end: 9;
}

.cell--33 {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 7;
  grid-row-end: 11;
}

.cell--34 {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 7;
}

.cell--35 {
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 3;
  grid-row-end: 5;
}

.cell--36 {
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 3;
}

.cell--37 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 4;
  grid-row-end: 11;
}

.cell--38 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 4;
}

.cell--39 {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 4;
  grid-row-end: 7;
}

.cell--40 {
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 5;
  grid-row-end: 7;
}

.cell--41 {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 9;
  grid-row-end: 11;
}

.cell--42 {
  grid-column-start: 6;
  grid-column-end: 7;
  grid-row-start: 3;
  grid-row-end: 5;
}

.expand-btn {
  display: none;
}
.objectresize {
  min-width: 100vw;
  min-height: 430vh;
}

@media only screen and (max-width: 768px) {
  canvas:not(.v360-image-container) {
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: -10%;
    bottom: 0;
    right: 0;
    opacity: 100%;
    position: absolute;
    overflow: hidden;

    z-index: 2;
  }

  .wrapone {
    display: none;
  }

  .carousel .control-arrow:before,
  .carousel.carousel-slider .control-arrow:before {
    margin: 0 0px;
    display: inline-block;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    content: "";
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 4px solid #1a1a1a;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 4px solid #1a1a1a;
  }

  .carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-family: "Fira Code", monospace;
    font-size: 6px;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.9);
    color: #fff;
  }

  .objectresize {
    width: 100vw;
    min-height: 350vh;
    pointer-events: none;
    overflow: hidden;
  }
  .socials {
    font-size: 9px;
  }
  .projecttext {
    overflow: hidden;
    max-height: 0; /* Adjust this value to your desired initial height */
    transition: max-height 2s ease;
    padding-top: 1%;
  }

  .projecttext.expanded {
    max-height: 500px;
  }

  .expand-btn {
    padding-top: 4%;
    display: flex;
  }

  .card7 {
    width: 336px;
    height: 20px;
    padding: 15px;
  }
  .card1,
  .card3,
  .card5,
  .card6 {
    width: 150px;
    height: 20px;
    padding: 15px;
  }
  #work {
    display: flex;
    padding-top: 20%;
    padding-bottom: 20%;

    justify-content: center;
  }
  .about {
    margin-top: 20%;
  }

  .buttonmenutext {
    display: flex;
    width: 100vw;
    justify-content: center;
  }

  h3:not(.smallnumber) {
    font-size: 50px;
  }

  .smallnumber {
    font-size: 20px;
    padding: 5px;
  }

  .workborder {
    padding-top: 20%;
  }

  h1 {
    font-size: 25px;
    padding-top: 20%;
  }

  .footer {
    margin-bottom: 0;
    margin-top: 40%;
  }

  .galleryplacement {
    position: relative;
    padding-top: 15%;
  }
  .galleryplacement1 {
    position: relative;
    padding-top: 15%;
  }

  .carousel.carousel-slider {
    width: 300px;
  }

  .carouser-root {
    justify-content: center;
    align-items: center;
  }

  .carousel .thumb {
    -webkit-transition: border 0.15s ease-in;
    -moz-transition: border 0.15s ease-in;
    -ms-transition: border 0.15s ease-in;
    -o-transition: border 0.15s ease-in;
    transition: border 0.15s ease-in;

    display: none;
  }

  .carousel .control-dots .dot {
    width: 4px;
    height: 4px;
    margin: 0 4px;
  }

  .carousel .control-dots {
    margin: 5px 0;
  }

  h2 {
    font-size: small;
  }

  h4 {
    font-size: small;
  }

  .Project1,
  .Project2,
  .Project3,
  .Project4,
  .CAD {
    margin-left: 10%;
    margin-right: 10%;

    object-fit: contain;
    overflow: visible;
  }

  .Misc {
    margin-left: 10%;
    margin-right: 10%;

    object-fit: contain;
    overflow: visible;
  }

  .leftside1 {
    display: flex;
    position: relative;
    padding-top: 0;
    width: 300px;
    justify-content: flex-start;
  }

  .image1 {
    display: flex;

    position: relative;
    align-items: center;
    justify-content: flex-start;
    left: 0;
    padding-right: 0;
    margin-left: -10%;

    width: 350px;
    height: 450px;
    object-fit: none;
    padding-top: 5%;
    border-radius: 2px;

    left: 0;
  }

  .img_gallery2 {
    display: flex;
    position: relative;
    align-items: center;

    width: 350px;
    height: 450px;
    object-fit: contain;
    padding-top: 3%;
    border-radius: 2px;
    overflow: auto;

    left: 0;
  }

  .img_gallery3 {
    display: none;
  }

  .img_gallery4 {
    height: 160px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 5%;
    border-radius: 2px;
  }

  .img_gallery5 {
    height: 300px;
    width: 100%;
    object-fit: contain;
    margin-bottom: 5%;
    border-radius: 2px;
  }

  .img_gallery6 {
    height: 280px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 5%;
    border-radius: 2px;
  }

  .glassmagnifier {
    pointer-events: none;
    margin-left: 60%;
  }

  p {
    font-size: 10px;
  }

  .workbody {
    display: block;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    overflow: visible;
    padding-top: 20%;
  }

  #workdescription {
    padding-left: 0;
    height: auto;
    width: auto;
  }

  .h2description {
    font-size: 10px;
  }

  .bigimage {
    display: flex;
    padding-top: 10%;
    width: 300px;
    height: auto;
  }

  .tsbox {
    display: none;
  }

  .v360-image-container {
    width: 35vw;
    object-fit: contain;
    display: flex;
    pointer-events: all;
    cursor: pointer;
  }

  .v360-viewer-container {
    display: flex;
    width: 35vw;
    pointer-events: all;
    cursor: pointer;
  }

  .centered {
    display: none;
  }

  .gridexp {
    /* background-image: url(/public/img/bal2.png);

    background-size: 110%;
    background-repeat: no-repeat;
    background-position: bottom -60px right 0px; */

    padding-top: 5%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 16vw);
    grid-gap: 3px;
    height: auto;
  }

  .gridexpmisc {
    /* background-image: url(/public/img/bal2.png);

    background-size: 110%;
    background-repeat: no-repeat;
    background-position: bottom -60px right 0px; */

    padding-top: 5%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 16vw);
    grid-gap: 3px;
    height: auto;
  }

  .cell--video4 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 6;
  }
  .cell--12 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 5;
  }
  .cell--13 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 9;
  }
  .cell--14 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 9;
    grid-row-end: 13;
  }
  .cell--15 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 6;
    grid-row-end: 8;
  }
  .cell--16 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 6;
    grid-row-end: 8;
  }
  .cell--17 {
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 8;
    grid-row-end: 10;
  }
  .cell--18 {
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 10;
    grid-row-end: 14;
  }
  .cell--19 {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .cell--20 {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 6;
  }
  .cell--21 {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 6;
    grid-row-end: 8;
  }
  .cell--24 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .cell--27 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 5;
  }
  .cell--22 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 5;
    grid-row-end: 7;
  }
  .cell--23 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 7;
    grid-row-end: 9;
  }
  .cell--28 {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 8;
    grid-row-end: 11;
  }
  .cell--29 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 9;
    grid-row-end: 11;
  }
  .cell--25 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 11;
    grid-row-end: 13;
  }
  .cell--26 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 11;
    grid-row-end: 13;
  }
  .cell--30 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 13;
    grid-row-end: 15;
  }

  .cell--video {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 4;
  }
  .cell--1 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 6;
  }
  .cell--2 {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 6;
  }
  .cell--3 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 7;
    grid-row-end: 12;
  }
  .cell--4 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 6;
    grid-row-end: 7;
  }

  /* Jewerly */

  .cell--video2 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
  }

  .cell--5 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
  }

  .cell--7 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 5;
  }

  .cell--8 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 5;
  }
  /* Jewerly */

  .cell--9 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 8;
  }
  .cell--10 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 8;
    grid-row-end: 12;
  }
  .cell--11 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 5;
  }

  .cell--video5 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .cell--video6 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 6;
  }

  .cell--31 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 11;
    grid-row-end: 13;
  }
  .cell--32 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 5;
  }
  .cell--33 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .cell--34 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .cell--35 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 10;
    grid-row-end: 11;
  }
  .cell--36 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 10;
    grid-row-end: 13;
  }

  .cell--37 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 10;
    grid-row-end: 13;
  }
  .cell--38 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 7;
    grid-row-end: 10;
  }
  .cell--39 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 6;
    grid-row-end: 7;
  }
  .cell--40 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 7;
  }
  .cell--41 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 7;
    grid-row-end: 10;
  }
  .cell--42 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 5;
  }

  .appear {
    font-size: 9px;
  }
}
